<template>
  <!-- 搜索专题 -->
  <div class="container">
    <!-- 列表 -->
    <div class="list">
        <div v-for=" (theme,index) in list" :key="theme.id" :style="{'float':index%2==0?'left':'right'}" >
                  <V-OneTheme :img="theme.img" :intro="theme.intro" :id="theme.id" :title="theme.title" @detail-click="gotoDetail" style="height:400px;width:500px"></V-OneTheme>
        </div>

    </div>
    <br/>
    <!-- 分页 -->
    <div class="page-nav">
        <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="hidePageNav"
            :total="totlePage"
            :page-size="pageSize"
            @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
        </el-pagination>
    </div>
  </div>

</template>

<script>
import OneTheme from "@/components/one-theme/type-one/index.vue";
import {DELETE,QUERY}  from '@/services/dao.js';
export default {
  data(){
    return{
      searchWord:"",
      hidePageNav:true,
      pageNum:0,
      pageSize:12,
      totlePage:0,
      list:[],
    }
  },
  created: function(){
        this.init();
  },
  watch: {
        '$route' (to, from) { //监听路由是否变化
            if(to.query.searchWord != from.query.searchWord){
                this.init();//重新加载数据
            }
        }
  },
  methods:{
          //初始化
      init(){
            this.searchWord=this.$route.query.searchWord;
            this.searchInfo(this.pageSize,0);
      },
      //前一页
      prevPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      //下一页
      nextPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      //当前页
      currentPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      gotoDetail(id){
        //alert(id);
        // console.log(this.id);
        this.$router.push({path:'/themeDetail', query: {
            id: this.id
        }});
        // console.log("文章id"+this.id);
      }
      ,
      async  searchInfo(limit,offset){
          let info=  await  QUERY("post","",'theme(limit: '+limit+', offset: '+offset+', where: {title: {_like: "%'+this.searchWord+'%"}}) {    id    createTime    content    img    title  }  theme_aggregate(where: {title: {_like: "%'+this.searchWord+'%"}}) {    aggregate {      count    }  }');
          this.list.splice(0,this.list.length);
          for(let i=0;i<info.data.theme.length;i++){
              this.list.push({
                  id:info.data.theme[i].id,
                  img:info.data.theme[i].img,
                  title:info.data.theme[i].title,
                  intro:info.data.theme[i].content,
                  time:info.data.theme[i].createTime,
              });
          }
          this.totlePage=info.data.theme_aggregate.aggregate.count;
      }
  },
  components:{
    'V-OneTheme':OneTheme
  }
}
</script>

<style scoped>
  .container{
    text-align: center;
  }
  .container .list,.container .page-nav,.page-nav .el-pagination{
     width: 1066px;
   }
  .container>div{
    display: inline-block;
  } 
  .list>div{
    padding: 10px;
  }
</style>